import type { Button } from '@repo/ui/components/Button.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import type { z } from 'zod'
import { useForm } from '~/hooks/useForm'
import { LoginSchema } from '~/routes/_auth.login/routeConfig'
import { Form, FormSubmitButton } from './Form'

export const LoginButton = ({
  className,
  provider,
  ...props
}: ComponentProps<typeof Button> & {
  provider: z.infer<typeof LoginSchema>['provider']
}) => {
  const form = useForm({
    schema: LoginSchema,
    fetcherKey: `${LoginSchema.shape.intent.value}_${provider}`,
    action: '/login',
    include: {
      provider,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  })

  return (
    <Form {...form}>
      <FormSubmitButton
        icon={provider === 'google' ? 'google-color' : 'microsoft-color'}
        variant="outline"
        className={cn('w-full', className)}
        {...props}
      >
        <span>
          Sign in with <span className="capitalize">{provider}</span>
        </span>
      </FormSubmitButton>
    </Form>
  )
}
